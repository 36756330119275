<template>
    <div>
      <b-card no-body class="mb-0">
        <b-form @submit="Update()">
          <validation-observer ref="simpleRules">
            <div v-if="isSideDataLoading" style="height:400px"
                          class="d-flex justify-content-center align-items-center">
                          <b-spinner label="Loading..."></b-spinner>
                      </div>
            <div class="m-2" v-else>
              <b-row class="d-flex justify-content-between w-100">
                <b-col md="12">
                    <h2 class="font-weight-bolder" style="font-weight:400;color:black">All Notification</h2>
                  </b-col>
                  <b-col md="12">
                    <b-media>
                        <template #aside>
                          <!-- <b-avatar size="32" :src="notification.avatar" :text="notification.avatar" :variant="notification.type" /> -->
                        </template>
                        <p class="media-heading d-flex justify-content-between">
                          <!-- <span class="font-weight-bolder">
                            {{ notification.title }}
                          </span> -->
                          <!-- <span class="dot" :class="{ 'dot-read': notification.read, 'dot-unread': !notification.read }"></span> -->
                        </p>
                        <!-- <small class="notification-text">{{ notification.subtitle }}</small><br> -->
                        <!-- <small class="notification-time">{{NotificationUpdate}}</small> -->
                      </b-media>
                  </b-col>
                </b-row>
            </div>
          </validation-observer>
        </b-form>
      </b-card>
    </div>
  </template>
  <script>
  import {
    BRow, BCol, BFormGroup, BFormInput, BCard, BFormFile, BButton, BForm,BSpinner,BMedia
  } from 'bootstrap-vue'
  import { ValidationProvider, ValidationObserver } from "vee-validate";
  import axios from '@/component/axios';
  import { quillEditor } from "vue-quill-editor";
  import {
    required
  } from "@validations";
  export default {
    components: {
      BCard,
      BMedia,
      BSpinner,
      BFormFile,
      ValidationProvider, ValidationObserver,
      BRow,
      BCol,
      BFormGroup,
      BFormInput,
      BButton,
      BForm,
      quillEditor,
    },
    data() {
      return {
        snowOption: {
          theme: "snow",
        },
        accessToken: localStorage.getItem("accessToken"),
        baseApi: process.env.VUE_APP_APIENDPOINT,
        data: [],
        isSideDataLoading: false,
      }
    },
    mounted() {
      this.getNotification();
    },
    methods: {
      async getNotification() {
        this.isSideDataLoading = false
        await axios({
          method: "GET",
          url: `${this.baseApi}nutrition/get`,
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${this.accessToken}`,
          },
        })
          .then((json) => {
            this.data = json?.data
           
          })
          .catch((error) => console.log(error, "error"))
                  .finally(() => {
                      this.isSideDataLoading = false
                  })
      },
    }
  }
  </script>
  <style lang="scss">
  @import '@core/scss/vue/libs/vue-select.scss';
  @import "@core/scss/vue/libs/quill.scss";
  </style>
  
  <style lang="scss">
  @import '@core/scss/vue/libs/vue-select.scss';
  </style>
  
  